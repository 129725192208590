import { Button, Card, Layout, Paragraph, Spinner } from "@vwfs-bronson/bronson-react";
import { FSOVCoreConfiguration, HasLeasingAccess } from "../services/FSOVSelectorCore";
import { useRequest } from "../hooks/useRequest";
import { TYPE_NOTIFICACION, SEVERITY_NOTIFICACION, createNotificationObject } from "../utils/Notification";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../store/store";
import { addNotification } from "../actions/notificationAction";
import { Notification } from "../components/UI/Notification/Notification";
import { FSOVCoreInterface } from "../types/types";
import { useEffect, useState } from "react";

export const HomePage = () => {
    const { requestId } = useRequest();
    const dispatch = useDispatch<AppDispatch>();
    const [callingFSOV, setCallingFSOV] = useState(false);
    const [isLeasingActivated, setIsLeasingActivated] = useState(false);

    const callFSOVCoreConfiguration = async (calculatorType: string) => {
        setCallingFSOV(true);
        const errorCodeVehicleOptionalsNotAllowed = "69000120";
        const FSOVCoreConfigurationResponse: FSOVCoreInterface | undefined = await FSOVCoreConfiguration(
            calculatorType,
            requestId,
        );
        const errors = FSOVCoreConfigurationResponse?.errors;

        if (errors && errors.length > 0) {
            const { internalValue, value, code } = errors[0];
            const errorDescription =
                internalValue && internalValue !== ""
                    ? internalValue
                    : value && value !== ""
                      ? value
                      : "Ha ocurrido un error";

            if (code === errorCodeVehicleOptionalsNotAllowed) {
                const notification = createNotificationObject({
                    Description: errorDescription,
                    Timeout: null,
                    Type: TYPE_NOTIFICACION.MODAL,
                    Severity: SEVERITY_NOTIFICACION.ERROR,
                    Id: "",
                    Title: "Opcionales incompatibles con el producto seleccionado",
                });
                dispatch(addNotification(notification));
                setCallingFSOV(false);

                return;
            }
            const notification = createNotificationObject({
                Description: errorDescription,
                Timeout: 5000,
                Type: TYPE_NOTIFICACION.TOAST,
                Severity: SEVERITY_NOTIFICACION.ERROR,
                Id: "",
                Title: "",
            });

            dispatch(addNotification(notification));
        }

        setCallingFSOV(false);
    };

    useEffect(() => {
        async function getRequestDealership() {
            try {
                const res = await HasLeasingAccess(requestId);
                if (res) setIsLeasingActivated(res);
            } catch (error) {
                console.log(error);
            }
        }

        if (requestId) {
            getRequestDealership();
        }
    }, [requestId]);

    return (
        <Layout equalHeight>
            <Layout.Item default={"1/4"} m="1/2">
                <Card
                    buttons={
                        <Button
                            key="001"
                            data-tr-component="Button Link"
                            data-tr-function="Continue"
                            icon="semantic-forward"
                            link
                            small
                            onClick={() => callFSOVCoreConfiguration("RENTING")}
                        >
                            Renting
                        </Button>
                    }
                    element="article"
                    footer
                    icon="semantic-car"
                    title="Ofertas de Renting, ¡elige la tuya!"
                >
                    <Paragraph style={{ textAlign: "justify" }}>
                        Ponte al volante de un coche del Grupo Volkswagen con una oferta de Renting a tu medida y con
                        contratación 100% online. Indica la duración y el kilometraje y disfrútalo con una cuota que
                        incluye todos los servicios.
                    </Paragraph>
                </Card>
            </Layout.Item>
            <Layout.Item default={"1/4"} m="1/2">
                <Card
                    buttons={
                        <Button
                            key="001"
                            data-tr-component="Button Link"
                            data-tr-function="Continue"
                            icon="semantic-forward"
                            link
                            small
                            onClick={() => callFSOVCoreConfiguration("FINANCE")}
                        >
                            Financiación
                        </Button>
                    }
                    element="article"
                    footer
                    icon="semantic-fs-payment"
                    title="Decide cómo financiar tu coche"
                >
                    <Paragraph style={{ textAlign: "justify" }}>
                        Si ya sabes qué coche quieres, ve a por él. Fináncialo hasta el 100% con accesorios y extras
                        incluidos y sin entrada obligatoria, solo necesitas decidir el importe de las cuotas y el
                        período de duración del contrato que mejor te convenga.
                    </Paragraph>
                </Card>
            </Layout.Item>
            {isLeasingActivated && (
                <Layout.Item default={"1/4"} m="1/2">
                    <Card
                        buttons={
                            <Button
                                icon="semantic-forward"
                                link
                                small
                                onClick={() => callFSOVCoreConfiguration("LEASING")}
                            >
                                Leasing
                            </Button>
                        }
                        element="article"
                        footer
                        icon="semantic-fs-payment"
                        title="Leasing para autónomos y pymes"
                    >
                        <Paragraph style={{ textAlign: "justify" }}>
                            Conduce el coche que deseas con la opción más flexible adaptada a profesionales. Cuotas
                            mensuales asequibles, pudiéndote beneficiar de ventajas fiscales.
                        </Paragraph>
                    </Card>
                </Layout.Item>
            )}
            <Layout.Item default={"1/4"} m="1/2">
                <Card
                    buttons={
                        <Button
                            key="001"
                            data-tr-component="Button Link"
                            data-tr-function="Continue"
                            icon="semantic-forward"
                            link
                            small
                            onClick={() => callFSOVCoreConfiguration("ADDITIONAL_PRODUCTS")}
                        >
                            Productos Adicionales
                        </Button>
                    }
                    element="article"
                    footer
                    icon="semantic-service"
                    title="El plan que se adapta a ti"
                >
                    <Paragraph style={{ textAlign: "justify" }}>
                        Olvídate de los imprevistos con Long Drive Service, un plan con el que puedes contratar
                        mantenimiento, mantenimiento + desgaste y seguro, por una suscripción mensual con todos los
                        cuidados que tu coche necesita para que conduzcas sin preocupaciones.
                    </Paragraph>
                </Card>
            </Layout.Item>
            <Notification></Notification>
            <Spinner busy={callingFSOV} fullPage></Spinner>
        </Layout>
    );
};
